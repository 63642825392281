import Rails from '@rails/ujs';
import Turbolinks from 'turbolinks';
import { throttle, isPWASupported, isDarkMode, isAndroidInAppBrowser, postData, throttleCallback } from 'packs/util';
import { initializeFns,
	initializeFragileElement,
	updateScrollIndicatorPosition,
	turnOnDarkTheme,
	destroyAudioObject,
	enableImageZoom,
	showEscapeInAppBrowserBanner,
	showAddToHomeScreenPrompt,
	focusActivatedTab,
	initAttachments
} from 'packs/helper'; 

import 'lazysizes';

Rails.start();
Turbolinks.start();

//window.addEventListener('popstate', () => Turbolinks.visit());

document.addEventListener('scroll', () => throttleCallback(updateScrollIndicatorPosition));

document.addEventListener('turbolinks:before-visit', () => {
	const existEmbedScript = document.querySelector('.flourish-embed');
	if (existEmbedScript) {
		const meta = document.createElement('meta');
		meta.setAttribute('name', 'turbolinks-visit-control')
		meta.content = 'reload';
		document.getElementsByTagName('head')[0].appendChild(meta);
	}
})

document.addEventListener('turbolinks:load', () => {

	initializeFns();

	initializeFragileElement();

	initAttachments();

	if (window.audio) {
		destroyAudioObject();
	}

	if (isDarkMode()) {
		turnOnDarkTheme();
	}

	if(isAndroidInAppBrowser()) {
		showEscapeInAppBrowserBanner();
	}
	(async() => {
		if (isPWASupported()) {
			navigator.serviceWorker.register('/firebase-messaging-sw.js', { scope: '/' });

			showAddToHomeScreenPrompt();

			const { initializeNotification } = await import('packs/notification');
			initializeNotification();

		}
	})();

	if (/categories\/[0-9]+\/articles/.test(document.URL)) {
		focusActivatedTab();
	}

});
