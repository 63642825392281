import * as FNS from 'packs/fns';
import { isMobile, getCookie, setCookie, downloadFile, previewFile } from 'packs/util';
import icons from 'packs/icons'

export const initializeFns = () => {
  document.querySelectorAll('.fn').forEach((fna) => {
    //fna.classList.remove('fn');
    fna.addEventListener('click', (ev) => {
      //ev.preventDefault();

      const act = fna.getAttribute('data-fn');
      let args = fna.getAttribute('data-args');
      if (args) {
        args = args.split(/\s*,\s*/).concat([ev]);
      } else {
				args = [ev];
			}
      FNS[act](...args || []);
    });
  });
}

export const initializeFragileElement = () => {
	document.addEventListener('mouseup', (ev) => {
		document.querySelectorAll('.fragile').forEach((container) => {
			if (container.contains(ev.target) || ev.target.classList.contains('togglable')) return;
			container.classList.add('hidden');

			if (!document.querySelector('#nl-subs-modal').classList.contains('hidden')) return;

			if (!document.querySelector('#sitemap').contains(ev.target)) {
				document.body.classList.remove('hidden');
			}
		});
	});
}

export const initAttachments = () => {
	document.querySelectorAll('.se-attachment-item').forEach((el) => {
		let attachmentId
		const match = el.className.match(/se-attachment-(\d+)/)
		if (match && match[1]) {
			attachmentId = match[1]
		} else {
			return
		}

		const isPreviewable = el.classList.contains('se-attachment-previewable')

		const previewButtonHtml = `<button type='button' class='se-attachment-preview-button' data-action='preview-attachment' title='미리보기'>${icons.preview}</button>`
		const downloadButtonHtml = `<button type='button' class='se-attachment-download-button' data-action='download-attachment' title='다운로드'>${icons.download_file}</button>`
		const buttonsHtml = `${isPreviewable ? previewButtonHtml : ''}${downloadButtonHtml}`

		el.insertAdjacentHTML('beforeend', buttonsHtml)

		el.addEventListener('click', async (ev) => {
			const action = ev.target.getAttribute('data-action')
			if (!action) return

			if (action === 'download-attachment') {
				await downloadFile(`/clips/${attachmentId}/download`)
			} else if (action === 'preview-attachment') {
				await previewFile(`/clips/${attachmentId}/preview`)
			}
		})
	})
}

export const enableImageZoom = () => {
	const images = document.querySelectorAll('.article-body figure.image img');
	images.forEach((image) => {
		image.classList.add('lazyload');
		image.addEventListener('lazyloaded', () => {
			let zoomable = false;

			if (isMobile()) {
				if (image.naturalWidth > 360) zoomable = true;
			} else {
				if (image.naturalWidth > 700) zoomable = true;
			}

			if (!zoomable) return;

			const figure = image.parentElement;

			const wrapper = document.createElement('div');
			wrapper.setAttribute('class', 'wrapper');
			wrapper.appendChild(image);

			const icon = document.createElement('img');
			const railsEnv = document.querySelector('#rails_env').value;
			const s3host = railsEnv == 'production' ? 'https://assets.repress.co.kr' : 'https://stg-assets.repress.co.kr';
			icon.src = `${s3host}/statics/images/icon_zoom.png`;
			icon.setAttribute('class', 'exp');
			icon.setAttribute('style', 'cursor:pointer');
			icon.addEventListener('click', () => {
				document.querySelector('#photo-viewer').classList.remove('hidden');
				document.body.classList.add('hidden');
				document.querySelector('#photo-viewer figure img').setAttribute('src', image.src);
				const figcaption = figure.querySelector('figcaption').textContent
				document.querySelector('#photo-viewer figure figcaption').textContent = figure.querySelector('figcaption').textContent;
			});
			wrapper.appendChild(icon);

			figure.insertAdjacentElement('afterbegin', wrapper);
		});
	});
}

export const isArticlePage = () => (/articles\/[0-9]+/.test(document.URL));

export const updateScrollIndicatorPosition = () => {
	const scrollTop = document.documentElement.scrollTop;
	const progressIndicator = document.querySelector('#progress-indicator');
	const sitemap = document.querySelector('#sitemap');
	const gnb = document.querySelector('#gnb');
	let targetScrollTop = 175;

	if (/articles\/[0-9]+/.test(document.URL)) {
		const height = document.documentElement.scrollHeight - document.documentElement.clientHeight;  
		progressIndicator.style.width = (scrollTop / height) * 100 + '%';
		progressIndicator.classList.toggle('hidden', scrollTop <= 360);
		targetScrollTop = 345;
	} else if (/categories\/[0-9]+\/articles/.test(document.URL)) {
		targetScrollTop = 265;
	}
	sitemap.classList.toggle('position-fixed', scrollTop > targetScrollTop);
	gnb.classList.toggle('hidden', scrollTop <= targetScrollTop);
}

export const turnOnDarkTheme = () => {
	document.body.classList.add('dark-mode');
	document.querySelector('button#theme-switching').classList.add('dark-mode');
	document.querySelectorAll('img.switchable').forEach((img) => {
		const basename = img.getAttribute('data-basename');
		const railsEnv = document.querySelector('#rails_env').value;
		const s3host = railsEnv == 'production' ? 'https://assets.repress.co.kr' : 'https://stg-assets.repress.co.kr';

		img.setAttribute('src', `${s3host}/statics/images/${basename}_darkmode.png`); 
	});
}

export const showAddToHomeScreenPrompt = () => {
	const trigger = document.querySelector('#ad-layout-top.a2hs');
	if (!trigger) return;

	let deferredPrompt;
	let isPromptShown = false;

	window.addEventListener('beforeinstallprompt', (ev) => {
		ev.preventDefault();
		deferredPrompt = ev;

		trigger.classList.remove('hidden');

		if (!isPromptShown) {
			isPromptShown = true;

			trigger.addEventListener('click', (ev) => {
				trigger.classList.add('hidden');

				deferredPrompt.prompt();
				deferredPrompt.userChoice.then((choiceResult) => {
					if (choiceResult.outcome === 'accepted') {
						console.log('user accepted');
					} else {
						isPromptShown = true;
						console.log('user dismissed');
					}
					deferredPrompt = null;
				});
			});
		}

	});
}

export const showEscapeInAppBrowserBanner = () => {
	if (!getCookie('iabb_exp_x')) {

		const mtwBanner =  document.querySelector('.mtw-banner');
		mtwBanner.classList.remove('hidden');
		mtwBanner.querySelector('button.move-now').addEventListener('click', () => {
			location.href = `intent://${location.href.replace(/https?:\/\//i, '')}#Intent;scheme=http;package=com.android.chrome;end`;
			setCookie('iabb_exp_x', '1', 1);
		});
		mtwBanner.querySelector('button.not-now').addEventListener('click', () => {
			mtwBanner.classList.add('hidden');
			setCookie('iabb_exp_x', '1', 1);
		});
	}
}

export const focusActivatedTab = () => {
	if (!isMobile()) return;

	const navContainer = document.querySelector('nav.category');
	const activeItem = document.querySelector('nav.category a.on');

	const containerLeft = navContainer.getBoundingClientRect().left;
	const containerWidth = navContainer.offsetWidth;
	const itemLeft = activeItem.getBoundingClientRect().left - containerLeft;
	const itemWidth = activeItem.offsetWidth;

	if (itemLeft < 0 || itemLeft + itemWidth > containerWidth) {
		navContainer.scrollLeft += itemLeft;
	}
}

export const destroyAudioObject = () => {
	window.audio.pause();
	window.audio = null;
}
