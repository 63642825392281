import { isMobile, isDarkMode, setCookie, destroyCookie, popup, generateCustomAlert } from 'packs/util';

export const switchColorScheme = () => {
	isDarkMode() ? destroyCookie('pcs_dk') : setCookie('pcs_dk', '1');

	location.reload();
}

export const toggleClass = (selectors, _class) => {
	selectors.split(';').forEach((selector) => {
		document.querySelector(selector).classList.toggle(_class);
	});
}

export const submitNewsletterSubscriptionForm = () => {
	const name = document.querySelector('input#name').value.trim();
	const email = document.querySelector('input#email').value.trim();
	const agreed = document.querySelector('input#agree').checked;
	if(!name) {
		alert('이름을 입력해주세요.');
		return;
	}
	if(!email) {
		alert('이메일을 입력해주세요.');
		return;
	}
	if(!/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(email)) {
		alert('이메일 형식이 올바르지 않습니다.');
		return;
	}
	if(!agreed) {
		alert('개인정보 수집 및 이용에 동의해주세요.');
		return;
	}
	const xhr = new XMLHttpRequest();
	const reqUrl = `/subscribe_newsletter?name=${name}&email=${email}`;
	xhr.onreadystatechange = () => {
		if(xhr.readyState === 4) {
			const res = JSON.parse(xhr.responseText);
			if(res.code === 200) {
				alert('뉴스레터 구독 신청이 완료되었습니다.');
				toggleClass('#dim;#nl-subs-modal;body', 'hidden');
			} else if(res.code === 409) {
				alert('이미 구독중인 이메일 입니다.');
			} else if(res.code === 503) {
				alert('일시적으로 오류가 발생하였습니다. 잠시 후 다시 시도해 주세요.');
			}
		}
	}
	xhr.open('POST', reqUrl);
	xhr.send();
}

export const toggleAudio = () => {
	const audioButton = document.querySelector('button.audio');
	if (!audioButton) return;

	if (!window.audio) {
		const audioSrc = audioButton.getAttribute('data-audio-src');
		window.audio = new Audio(audioSrc);
	}

	const devicePlatform = isMobile() ? 'mobile' : 'desktop';
	document.querySelectorAll(`button.audio.${devicePlatform}`).forEach((audioButton) => {
		const audioButtonIcon = audioButton.querySelector('img');
		if (audioButton.classList.contains('playing')) {
			audioButton.classList.remove('playing');
			if (devicePlatform === 'mobile') {
				audioButtonIcon.setAttribute('src', audioButtonIcon.src.replace('play', 'pause'));
			} else {
				audioButtonIcon.setAttribute('src', audioButtonIcon.src.replace('playing_colored.gif', 'pause_colored.png'));
			}
			window.audio.pause();
		} else {
			audioButton.classList.add('playing');
			if (devicePlatform === 'mobile') {
				audioButtonIcon.setAttribute('src', audioButtonIcon.src.replace('pause', 'play'));
			} else {
				audioButtonIcon.setAttribute('src', audioButtonIcon.src.replace('pause_colored.png', 'playing_colored.gif'));
			}
			window.audio.play();
		}
	});
}

export const openSearchForm = (selector, inputField) => {
	toggleClass(selector, 'hidden');
	document.querySelector(inputField).focus();
}

export const shareOnFacebook = () => {
	const shareURL = encodeURIComponent(document.URL);
	popup('https://www.facebook.com/sharer/sharer.php?u=' + shareURL, 'shareFb', 500, 500);
}

export const shareOnTwitter = () => {
	const shareURL = encodeURIComponent(document.URL);
	const title = encodeURIComponent(document.querySelector('meta[property="og:title"]').getAttribute('content'));
	popup('https://twitter.com/intent/tweet?url=' + shareURL + '&text=' + title, 'shareTwt', 500, 500);
}

export const shareOnKakaoTalk = () => {
	(() => {
		const title = document.querySelector('meta[property="og:title"]').getAttribute('content');
		const desc = document.querySelector('meta[property="og:description"]').getAttribute('content');
		const imageUrl = document.querySelector('meta[property="og:image"]').getAttribute('content');
		const shareUrl = document.querySelector('meta[property="og:url"]').getAttribute('content');
		Kakao.Link.sendDefault({
			objectType: 'feed',
			content: {
				title: title,
				description: desc,
				imageUrl: imageUrl,
				link: {
					mobileWebUrl: shareUrl,
					webUrl: shareUrl
				}
			},
			buttons: [
				{
					title: '웹으로 보기',
					link: {
						mobileWebUrl: shareUrl,
						webUrl: shareUrl
					}
				}
			]
		});
	})();
}

export const copyURLToClipboard = async () => {
	document.querySelectorAll('.layer-popup').forEach((el) => {
		el.classList.add('hidden');
	});
	document.body.style = 'overflow: hidden; pointer-events:none';
	try {
		await navigator.clipboard.writeText(document.location.href);
		generateCustomAlert('URL이 복사되었습니다.');
	} catch (e) {
		alert('URL 복사를 지원하지 않는 브라우저 입니다.');
	}
}

export const changeFontSize = (fontSize) => {
	document.querySelector('.article-body .content').className = `content ${fontSize}`;
}

// export const print = () => {
// 	window.print();
// }


export const print = () => {
	// 기존 추가된 스타일 제거
	const existingStyle = document.querySelector('#print-style');
	if (existingStyle) {
		document.head.removeChild(existingStyle);
	}

	// 기사 제목 가져오기
	const articleTitle = document.querySelector('h1')?.innerText || '기사 제목 없음';

	// 프린트용 스타일 추가
	const style = document.createElement('style');
	style.id = 'print-style';
	style.innerHTML = `
    @media print {
        @page {
            margin-top: 20mm;
        }
        body::before {
            content: none; /* 추가된 제목 제거 */
        }
    }
    `;
	document.head.appendChild(style);

	// 프린트 창 띄우기
	window.print();

	// 프린트 후 스타일 제거
	document.head.removeChild(style);
};


